import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RootUrl } from 'src/app/root/root-url';

@Injectable({
  providedIn: 'root'
})
export class ManagePagesService {
  url = new RootUrl();
  readonly _url = this.url.rootURL;

  constructor(private http: HttpClient) { }



  getAllPages(dtParams: any, id: any): Observable<any> {
    const httpoptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
       // 'Authorization': 'Bearer ' + this._token,
      })
    };
    return this.http.post<any>(this._url + 'api/ManagePages/GetAllPages?parentId=' + id, JSON.stringify(dtParams), httpoptions);
  }

  getPageById(id: any): Observable<any> {
    const httpoptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
       // 'Authorization': 'Bearer ' + this._token,
      })
    };
    return this.http.get<any>(this._url + 'api/ManagePages/GetPageByID?PageId=' + id, httpoptions);
  }

  add_UpdatePage(data: any): Observable<any> {
    const httpoptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
       // 'Authorization': 'Bearer ' + this._token,
      })
    };
    return this.http.post<any>(this._url + 'api/ManagePages/AddorUpdatePage', data, httpoptions);
  }
  // "http://hrapi.txsdemos.com/api/ManagePages/DeletePage?pageId=01&employeeID=01"


active_deactivePage(id: any): Observable<any> {
  const httpoptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
     // 'Authorization': 'Bearer ' + this._token,
    })
  };
  return this.http.get<any>(this._url + 'api/ManagePages/DeletePage?pageId='+ id+'&employeeID='+ localStorage.getItem("empId"), httpoptions);
}
getPagepermissions(roleId:any,PageName:any):Observable<any>{
  const httpoptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  return this.http.post(this._url + 'api/ManageRolePermission/GetPagePermission?roleId='+parseInt(roleId)+'&PageName='+PageName  ,{},httpoptions)   
}
}
