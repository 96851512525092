import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AllModulesService } from "src/app/all-modules/all-modules.service";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { Subject } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { NgForm } from '@angular/forms';

import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { SpinnerService } from "src/app/spinner/spinner.service";
import { ManageCredentialsModel } from "src/app/Models/credentials";
import { Title } from "@angular/platform-browser";

import { HttpErrorResponse } from '@angular/common/http';
import { CredentialsService } from "src/app/services/credentialservices/credentials.service";
import { RootUrl } from "src/app/root/root-url";
import { ManagePagesService } from "src/app/services/administration/manage-pages.service";

declare const $: any;
@Component({
  selector: 'app-manage-credentials',
  templateUrl: './manage-credentials.component.html',
  styleUrls: ['./manage-credentials.component.css']
})
export class ManageCredentialsComponent implements OnInit {
  modelobj = new ManageCredentialsModel();
  URLPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  public ddlProjectModulesList: Array<Select2OptionData>;
  public optionsForSingleDDL: Options;
  public _value: string[];
  public credentialList = [];
  public dataCheck: boolean = false;
  public dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public lstEmployee: any;
  public url: any = "employeelist";
  public cId: any;
  public editId: any;
  public addCredentialsForm: FormGroup;
  public editProjectForm: FormGroup;
  public env: boolean
  public pipe = new DatePipe("en-US");
  public statusValue: any;
  public dtTrigger: Subject<any> = new Subject();
  public DateJoin: any;
  public title = 'HRMS | Manage Credentials';
  public projectList: any[];
  public pTypelist: any[];
  public credentialListId = 0;
  public deleteModal: boolean;
  public addModal: boolean;
  // public module:any;
  Create:boolean=false;
  Edit:boolean = false;
  Delete:boolean = false; 
  rootobJ=new RootUrl();
  readonly _empRoleId=this.rootobJ.empRole;

  constructor(
    private srvModuleService: AllModulesService,private managePagesServices:ManagePagesService,
    private CredentialsServic: CredentialsService,
    private _toastr: ToastrService,
    private _spinner: SpinnerService, private _titleService: Title) {
    this._titleService.setTitle(this.title);
  }

  ngOnInit(): void {
    this.optionsForSingleDDL = {
      width: '100%',
      //tags: true
    };
    this.getDprojects()
    $(".floating")
      .on("focus blur", function (e: { type: string; }) {
        $(this)
          .parents(".form-focus")
          .toggleClass("focused", e.type === "focus" || this.value.length > 0);
      })
      .trigger("blur");
      this.pagePermissions()
    this.getAllCredentiallist()
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dtTrigger.next();
    }, 1000);
  }

  // manually rendering Data table
  rerender(): void {
    this._spinner.requestStarted();
    $("#datatable").DataTable().clear();
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
    this.lstEmployee = [];
    // this.loadEmployee();
    setTimeout(() => {
      this.dtTrigger.next();
    }, 1000);
    this._spinner.requestEnded();
  }

  // Get Employee  Api Call
  // loadEmployee() {
  //   this._spinner.requestStarted();
  //   this.srvModuleService.get(this.url).subscribe((data) => {
  //     this.lstEmployee = data;
  //     this.rows = this.lstEmployee;
  //     this.srch = [...this.rows];
  //   });
  //   this._spinner.requestEnded();
  // }
  pagePermissions()
  { 
     
    var RoleId = this._empRoleId;
    this.managePagesServices.getPagepermissions(RoleId,"Manage Credentials").subscribe((resp:any)=>{
      if(resp.valid)
      {
        if(resp.items[1].selected)
        {
          this.Create=true;
        }
        if(resp.items[2].selected){
          this.Edit = true;
        }
        if(resp.items[3].selected){
          this.Delete = true;
        }

      }
    })
  }

  getAllCredentiallist() {
    this._spinner.requestStarted();
    this.dtOptions = {
      lengthChange: true,
      pagingType: "simple_numbers",
      destroy: true,
      columnDefs: [{ targets: [5], orderable: false }],
      order: [[0, 'desc']],
      pageLength: 10,
      serverSide: true,
      processing: true,
      searching: true,
      scrollX:true,
      ajax: (dataTablesParameters: any, callback,) => {
        this.CredentialsServic
          .getAllCredentilas(dataTablesParameters).subscribe((resp: any) => {
            this.credentialList = resp.items;
            if (resp.items.length == 0) {
              this.dataCheck = true;
            }
            callback({
              recordsTotal: resp.totalCount,
              recordsFiltered: resp.searchedCount,
              aaData: []
            });
            this._spinner.requestEnded();
          },
            (err: HttpErrorResponse) => {
              //If Not Authorized 
              if (err.status == 401) {
                console.log(123)
              }
              this._toastr.error(err.message);
              //Loader End
              this._spinner.requestEnded();
            });
      }
    }
  }



  deleteCredentials() {
    this.CredentialsServic.deleteCredentials(this.cId).subscribe((res) => {
      if (res.valid) {
        console.log(res.item)
        $("#delete_Credentials").modal("hide");
        this.reDraw()
        this._toastr.success(res.message);
      } else {
        $("#delete_Credentials").modal("hide");
        this._toastr.error(res.message);
        this._spinner.requestEnded();
      }
    }, (err: HttpErrorResponse) => {
      //If Not Authorized 
      this._toastr.error(err.error.message);
      this._spinner.requestEnded();
    })
    //Loader End
  }

  //getting the status value
  getStatus(data: any) {
    this.statusValue = data;
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  reDraw(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.clear().draw();

      this.dtTrigger.next();
    });
  }

  //Reset form page
  resetForm(form: any) {

    if (form) {
      this.addModal = false;
      form.resetForm();
      this.modelobj.credentialID = 0
    } else {
      this.deleteModal = false;
    }

    // console.log(this.modelobj.credentialID)
  }
  getDprojects() {
    this.CredentialsServic.getbindProjects().subscribe((res) => {
      var plist = [];
      for (var i = 0; i < res.items.length; i++) {
        plist.push({ id: res.items[i].projectID.toString(), text: res.items[i].projectTitle })
      }
      this.projectList = plist;
    })
  }
  addCredentials(form: NgForm) {
    // console.log(this.modelobj.credentialID)
    this._spinner.requestStarted();
    if (form.valid) {
      this.CredentialsServic.addCredentials(this.modelobj).subscribe((res) => {
        if (res.valid) {
          $("#add_Credentials").modal("hide");
          this._toastr.success(res.message);
          this.resetForm(form);
          this.reDraw()
          this._spinner.requestEnded();
        } else {
          this._toastr.error(res.message);
          this.resetForm(form);
          $("#add_Credentials").modal("hide");
          this._spinner.requestEnded();
        }

      }, (err: HttpErrorResponse) => {
        this._toastr.error(err.error.message);
        this._spinner.requestEnded();
      })
    }
    this._spinner.requestEnded();
  }
  edit(credentialID: any) {
    this.addModal = true;
    // console.log(this.modelobj.credentialID)
    this.CredentialsServic.getCredentials(credentialID).subscribe((res) => {
      console.log(res)
      this.modelobj = res.item
      this.credentialListId = res.item.credentialID
    })
  }
  onDeleteModal(id: any) {
    this.deleteModal = true;
    this.cId = id
  }
  onModalOpen() {
    this.addModal = true;
  }

}
