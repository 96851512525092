import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RootUrl } from 'src/app/root/root-url';

@Injectable({
  providedIn: 'root'
})
export class CredentialsService {
  url = new RootUrl();
  readonly _url = this.url.rootURL;
 

  constructor(private http: HttpClient) { }
  
  getAllCredentilas(dtParams: any) {
    const httpoptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
// 'Authorization': 'Bearer ' + this._token,,
      })
    };
    return this.http.post<any>(this._url + 'api/ManageCredentials/GetAllCredentials', JSON.stringify(dtParams), httpoptions)
  }
  deleteCredentials(cId: any): Observable<any> {
    const httpoptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
// 'Authorization': 'Bearer ' + this._token,,
      })
    };
    return this.http.get(this._url + 'api/ManageCredentials/DeleteCredentials?CredentialID=' + cId, httpoptions)
  }
  addCredentials(modelobj: any): Observable<any> {
    const httpoptions = {
      headers: new HttpHeaders({
// 'Authorization': 'Bearer ' + this._token,,
      })
    };
    return this.http.post<any>(this._url + 'api/ManageCredentials/AddCredential', modelobj, httpoptions);
  }
  getCredentials(credentialID: any): Observable<any> {
    const httpoptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
// 'Authorization': 'Bearer ' + this._token,,
      })
    };
    return this.http.get(this._url + 'api/ManageCredentials/GetCredentialsByID?CredentialID=' + credentialID, httpoptions)
  }
  getbindProjects(): Observable<any> {
    const httpoptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + this._token,
      })
    };
    return this.http.get(this._url + 'api/LookUp/BindProjects', httpoptions)
  }

}
