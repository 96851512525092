import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/internal/operators';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private _spinner: SpinnerService,private router: Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // if (req.headers.get('No-Auth') == "True")
    //   return next.handle(req.clone());

    const clonedreq = req.clone({
      headers: req.headers.set("Authorization", "Bearer " + localStorage.getItem('token'))
    });
    return next.handle(clonedreq).pipe(tap((succ: any) => {
      
     },
      (err: any) => { 
        if (err.status === 401)
          this.router.navigateByUrl('/login');
          this._spinner.requestEnded();
      }
    ));

    return next.handle(req);
  }
}