
import { Directive, Input, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { NgSelect2Component } from 'ng-select2';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements OnInit, AfterViewInit {
  // @Input() appAutofocus: boolean;

  constructor(private element: ElementRef) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.checkEle()
  }

  checkEle() {
    setTimeout(() => {
      this.element.nativeElement.focus();
    }, 500);
  }
}