export class RootUrl {
  // public rootURL = "http:\\//tpl33/HRMS/"
  // public rootURL = "http://hrapi.txsdemos.com/";
 // public rootURL = "https://hrbetapi.technoxis.com/";
 // public rootURL = "http://localhost:44372/";
  public rootURL = "https://hrbetapi.technoxis.com/";
  //public rootURL="http://localhost:44372/"
  public token = localStorage.getItem('token');
  public userName = localStorage.getItem('userName');
  public userProfile = localStorage.getItem("userProfile");
  public empId = localStorage.getItem("empId");
  public empName = localStorage.getItem("empName");
  public empRole = localStorage.getItem("empRole");
  public chatId = localStorage.getItem('chatId');

}
