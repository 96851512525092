import { map } from 'rxjs/operators';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appAccessControl]'
})
export class AccessControlDirective implements OnInit {
  @Input("PageName") PageName: any;
  @Input("accessType") accessType: any;
  public item: any
  public permissionList: any[] = [];

  constructor(private elementRef: ElementRef) { }

  ngOnInit() {
    this.permissionList = JSON.parse(localStorage.getItem('permissions'))
    this.item = [
      {
        PageID: 1,
        PageName: "Manage Modules",
        accessControls: [
          {
            value: "View",
            Selected: true
          },
          {
            value: "Create",
            Selected: true
          },
          {
            value: "Edit",
            Selected: true
          },
          {
            value: "Delete",
            Selected: true
          },
        ]
      },
      {
        PageID: 2,
        PageName: "Manage Clients",
        accessControls: [
          {
            value: "View",
            Selected: true
          },
          {
            value: "Create",
            Selected: true
          },
          {
            value: "Edit",
            Selected: true
          },
          {
            value: "Delete",
            Selected: true
          },]

      },
      {
        PageID: 3,
        PageName: "Assign Task",
        accessControls: [
          {
            value: "View",
            Selected: true
          },
          {
            value: "Create",
            Selected: true
          },
          {
            value: "Edit",
            Selected: true
          },
          {
            value: "Delete",
            Selected: true
          },]

      },
      {
        PageID: 4,
        PageName: "Manage Employees",
        accessControls: [
          {
            value: "View",
            Selected: true
          },
          {
            value: "Create",
            Selected: true
          },
          {
            value: "Edit",
            Selected: true
          },
          {
            value: "Delete",
            Selected: true
          },
        
        ]

      },
    ]
    this.checkAccess();
  }
  
  checkAccess() {
    const page = this.permissionList.find(ele => ele.pageName == this.PageName)
    this.elementRef.nativeElement.style.display = page[this.accessType] ? '' : 'none';
  }

}