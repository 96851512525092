import { MustMatchDirective } from './../Validators/must-match.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomDatePipe } from 'src/assets/pipes/custom-date.pipe';
import { AccessControlDirective } from '../accessControl/access-control.directive';
import { AutofocusDirective } from '../autofocus.directive';
import { NgSelect2Module } from 'ng-select2';
import { DragfileDirective } from '../directives/dragfile.directive';



@NgModule({
  declarations: [CustomDatePipe, MustMatchDirective,AccessControlDirective,AutofocusDirective,],
  exports: [
    CustomDatePipe, MustMatchDirective,AccessControlDirective,AutofocusDirective
  ],
  imports: [
    CommonModule,   
     NgSelect2Module,



  ]
})
export class SharingModule { }
