import { ManageCredentialsComponent } from './manage-credentials/manage-credentials.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CredentialsComponent } from './credentials.component';

const routes: Routes = [
  {
    path: "",
    component:CredentialsComponent,
    children:[{
      path:'manageCredentials',
      component:ManageCredentialsComponent
     }]
  },
  // {
  //   path: 'addCredentials',
  //   component: 
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CredentialsRoutingModule { }
