import { AuthGuard } from './auth/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AssignTaskComponent } from './all-modules/projects-management/assigntask/assign-task/assign-task.component';

const routes: Routes = [
  { path: 'login', loadChildren: () => import(`./login/login.module`).then(m => m.LoginModule) },
  
  { path: 'error', loadChildren: () => import(`./errorpages/errorpages.module`).then(m => m.ErrorpagesModule) },

  {
    path: '',
    loadChildren: () => import(`./all-modules/all-modules.module`).then(m => m.AllModulesModule),
    canActivate: [AuthGuard]
  },
  // { path: '**', loadChildren: () => import(`./login/login.module`).then(m => m.LoginModule) },
  { path: '**', loadChildren: () => import(`./errorpages/errorpages.module`).then(m => m.ErrorpagesModule) },

  

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', useHash: false, })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
